"use client"

import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import Link from "next/link"
import { LinkedinIcon, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"

const StyledLink = styled.div<{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  position: relative;
  font-size: 18px;
  width: 100%;
  line-height: 24px;
  white-space: nowrap;
  color: red;

  text-decoration: none;
  p {
    font-weight: ${(props) => (props.selected ? "700" : "400")};
    text-decoration: none;
  }
  padding: 16px;
  border-bottom: ${(props) => (props.isLast ? "none" : "1px solid #F7F7F7")};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0;
    display: flex;
    align-items: center;
    transition: 300ms all ease;
    text-decoration: none;
    height: 100%;
    border-bottom: none;

    :hover {
      p {
        font-weight: 700;
        color: ${(props) => props.theme.colors.propBlue};
      }
    }
  }
`

type Props = {
  href: string
  selected: boolean
  title: string
  color?: string
  onClick?: () => void
  isLast?: boolean
}

function NavLink({ href, selected, title, color = sharedColors.black, onClick, isLast }: Props) {
  const theme = useTheme()
  return (
    <Link
      href={href || ""}
      target={href?.includes(".") ? "_blank" : "_self"}
      style={{
        textDecoration: "none",
      }}
    >
      {title === "linkedin" ? (
        <StyledLink onClick={onClick} selected={selected} color={color} isLast={isLast}>
          <NewText
            component="p"
            desktopFontSize={16}
            desktopLineHeight={"23px"}
            mobileFontSize={16}
            mobileLineHeight={"100%"}
            desktopFontWeight={selected ? 700 : 500}
            color={theme.colors.black}
            textTransform="uppercase"
          >
            <LinkedinIcon />
          </NewText>
        </StyledLink>
      ) : (
        <StyledLink onClick={onClick} selected={selected} color={color} isLast={isLast}>
          <NewText
            component="p"
            desktopFontSize={16}
            desktopLineHeight={"23px"}
            mobileFontSize={16}
            mobileLineHeight={"100%"}
            desktopFontWeight={selected ? 700 : 500}
            color={theme.colors.black}
          >
            {title}
          </NewText>
        </StyledLink>
      )}
    </Link>
  )
}

export default NavLink
